<template>
  <!-- 酒店管理系统-->
  <div class="wrapper">
    <Header></Header>
    <div class="body">
      <div class="pic1">
        <img class="img" :src="img1">
      </div>
      <div class="link1">
        <div class="title">
          <div class="title-big">五大部门，满足酒店需要</div>
          <div class="title-small">一个系统搞定，账务可以在一起统计</div>
        </div>
        <div class="forms1" v-for="(form,index) in forms1" :key="index">
          <div class="form1">
            <div class="form1_left">
              <div class="form1_icon">
                <img class="form1_img" :src="form.img">
              </div>
              <div class="form1_title">{{form.title}}</div>
            </div>
            <div class="form1_right">{{form.text}}</div>
          </div>
        </div>
        <Reserve></Reserve>
      </div>
      <div class="link2">
        <div class="title">
          <div class="title-big">多门店管理体系</div>
          <div class="title-small">支持连锁酒店</div>
        </div>
        <div class="pic2">
          <img class="img" :src="img2">
        </div>
        <div class="forms2" v-for="(form,index) in forms2" :key="index">
          <div class="form2">
            <div class="form2_title">{{form.title}}</div>
            <div class="form2_text">{{form.text}}</div>
          </div>
        </div>
        <Reserve></Reserve>
      </div>
      <div class="link3">
        <div class="title">
          <div class="title-big">可接入各种渠道</div>
          <div class="title-small">按需定制，满足您的特殊需求</div>
        </div>
        <div class="forms3">
          <div class="form3" v-for="(form,index) in forms3" :key="index">
             <div class="form3_icon">
               <img :src="form.img">
             </div>
             <div class="form3_title">{{form.title}}</div>
             <div class="form3_text">{{form.text}}</div>
          </div>
        </div>
        <Reserve class="form3_reserve"></Reserve>
      </div>
      <Record></Record>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Reserve from '../components/reserve.vue'
import Record from '../components/record.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'page5',
  components: {
    Header,
    Reserve,
    Record,
    Footer
  },
  data () {
    return {
      img1: require('@/assets/img/page5.1.png'),
      img2: require('@/assets/img/page5.3.png'),
      forms1: [{
        img: require('@/assets/img/page5.2.1.png'),
        title: '前台部',
        text: '办理入住/预订、住宿订单管理、客户管理（会员/普通用户/单位）、夜审、前台部员工权限、物品寄存、收据管理、报表管理'
      }, {
        img: require('@/assets/img/page5.2.2.png'),
        title: '商店部',
        text: '商品结算、订单管理、商品管理、商品分类、售卖点管理、报表管理，商店部员工权限管理等'
      }, {
        img: require('@/assets/img/page5.2.3.png'),
        title: '餐饮部',
        text: '前台点餐、订单管理、挂账到房间、菜品沽清、菜品管理、菜品剩余管理、菜品分类管理、报表管理、餐饮部员工权限等'
      }, {
        img: require('@/assets/img/page5.2.4.png'),
        title: '管理部',
        text: '夜审设置、交班设置、客房维护（房型、楼层楼栋、房间信息、房锁、早晚餐）、价格维护（会员价格体系、散客价格体系）等'
      }, {
        img: require('@/assets/img/page5.2.5.png'),
        title: '总办',
        text: '报表管理、总办员工权限管理、部门负责人管理、酒店员工账号信息管理'
      }],
      forms2: [{
        title: '总部创建门店',
        text: '总部后台统一创建门店，设定门店总管理账号密码，总管理登录对应的门店后台管理门店即可'
      }, {
        title: '总部查看所有报表',
        text: '总部后台可以查看所有门店经营数据，对所有门店经营了如指掌'
      }, {
        title: '总部查看所有客户数据',
        text: '查看所有门店入住客户情况，添加编辑会员/单位资料等'
      }, {
        title: '总部预订',
        text: '总部可以直接操作预订房间，门店那边就会收到预订单进行处理'
      }],
      forms3: [{
        img: require('@/assets/img/page5.3.1.png'),
        title: '第三方订单',
        text: '美团｜飞猪等'
      }, {
        img: require('@/assets/img/page5.3.2.png'),
        title: '网站',
        text: '手机端｜PC端'
      }, {
        img: require('@/assets/img/page5.3.3.png'),
        title: '小程序',
        text: '小程序预订'
      }, {
        img: require('@/assets/img/page5.3.4.png'),
        title: '电子设备',
        text: '平板｜售卖机等'
      }, {
        img: require('@/assets/img/page5.3.5.png'),
        title: '其他系统',
        text: '如对接财务系统'
      }, {
        img: require('@/assets/img/page5.3.6.png'),
        title: '更多',
        text: '根据您的需要'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  z-index: 1;
  margin: 0.42rem -0.08rem 0 -0.08rem;
}
.pic1 {
  height: 2.02rem;
  margin-bottom: 0.16rem;
}
img {
 width: 100%;
}
.title {
  margin-bottom: 0.15rem;
  .title-big {
    font-size: 0.17rem;
    font-family: PingFangSC-Medium;
    color: #1e1e1e;
    margin-bottom: 0.06rem;
  }
  .title-small {
    font-family: PingFangSC-Regular;
    color: #666666
  }
}
.link1 {
  text-align: center;
  .forms1 {
    margin: 0.16rem 0.16rem 0 0.16rem;
    .form1 {
      position: relative;
      display: flex;
      align-items: center;
      height: 0.7rem;
      margin-bottom: 0.05rem;
      border-radius: 0.08rem;
      box-shadow: 0 0.01rem 0.04rem 0 rgba(0, 0, 0, 0.16);
      .form1_left {
        margin: 0.12rem 0.185rem 0.155rem 0.15rem;
        .form1_icon {
          width: 0.42rem;
          margin-bottom: -0.05rem;
          .form1_img {
            width: 100%
          }
        }
        .form1_title {
          font-family: PingFangSC-Medium;
        }
      }
      .form1_right {
        font-family: PingFangSC-Regular;
        color: #333333;
        text-align: start;
        width: 2.585rem;
      }
    }
  }
}
.link2 {
  text-align: center;
  background-color: #f7f7f7;
  padding-top: 0.2rem;
  padding-bottom: 0.01rem;
  .pic2 {
    margin: 0 auto;
    width: 3.3rem;
  }
  .forms2 {
    margin: 0 0.16rem;
    text-align: start;
    .form2 {
      margin-bottom: 0.16rem;
      .form2_title {
        font-family: PingFangSC-Medium;
        font-size: 0.14rem;
        margin-bottom: 0.04rem;
      }
      .form2_text {
        font-family: PingFangSC-Regular;
        color: #666666
      }
    }
  }
}
.link3 {
  text-align: center;
  .title {
    margin-top: 0.2rem;
    margin-bottom: 0.24rem;
    .title-big {
      font-size: 0.17rem;
      font-family: PingFangSC-Medium;
      color: #1e1e1e;
      margin-bottom: 0.06rem;
    }
    .title-small {
      font-family: PingFangSC-Regular;
      color: #666666
    }
  }
  .forms3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 0.2rem;
    align-items: center;
    .form3 {
      margin-right: 0.27rem;
      margin-bottom: 0.32rem;
      width: 0.9rem;
      .form3_icon {
        width: 0.62rem;
        margin-bottom: 0.13rem;
        margin-left: 0.12rem;
      }
      .form3_title {
        font-family: PingFangSC-Medium;
        color: #333333;
        margin-bottom: 0.03rem
      }
      .form3_text {
        font-family: PingFangSC-Regular;
        color: #999999;
      }
    }
  }
  .form3_reserve {
    margin-top: -0.12rem;
  }
}

</style>
